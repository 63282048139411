import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import api from "../../services/api";
import { useState, useEffect } from "react";

export default function ImageAvatars() {
  const [dadosNome, setDadosNome] = useState({});

  useEffect(() => {
    api
      .get('/usuario/getMyUser')
      .then(function (res) {
        const user = res.data[0].nome;
        const [primeira, segunda] = user.toUpperCase().split(" ");

        const primeiraLetra = primeira.charAt(0) || '';
        const segundaLetra = segunda ? segunda.charAt(0) : primeira.charAt(1);

        setDadosNome({ primeiraLetra, segundaLetra });
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <Avatar
        alt="Avatar usuario"
        sx={{ width: 35, height: 35 }}
        style={{ cursor: "pointer" }}
        >
          <small>
            {`${dadosNome.primeiraLetra || ''}${dadosNome.segundaLetra || ''}` }
          </small>
        </Avatar>
    </Stack>
  );
}
