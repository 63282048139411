import React, { useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import api from '../../../../services/api';
import ScreenLogo from '../../../../components/LogoTelas';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function tipoDocumentoCriar() {
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();
  const [nome, setUnidade] = useState('');

  const Cadastrar = () => {
    api
      .post('/tipoDocumento/create', {
        nome: nome,
      })
      .then((res) => {
        setDouble(false);
        setUnidade('');
        toast.success( res.data.message || 'Tipo de documento cadastrado com sucesso!');
        setTimeout(function () {
          Navigate('/tipo-documento');
        }, 1500);
      })
      .catch((error) => {
        setDouble(false);
        toast.error(error.response.data.error || 'Erro ao cadastrar tipo de documento!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate(history.back());
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cadastrar Tipo de Documento" />

      <div className="Box-Body">
        <Input
          title={'Tipo (exemplo: Relatório de Inteligência)'}
          value={nome}
          onChange={(e) => setUnidade(e.target.value)}
        />

        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true), Cadastrar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
