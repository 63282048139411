import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Selects({ props, onChange, value, data, type, error, helperText, name, disabled }) {
  Selects.propTypes = {
    props: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    data: PropTypes.array.isRequired,
    type: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  const [inputValue, setInputValue] = useState(value)
	useEffect(() => {
		setInputValue(value)
	}, [value])

  return (
    <div style={{ width: '100%' }}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-label">{props}</InputLabel>
        <Select
          id="demo-simple-select-helper"
          disabled={disabled}
          name={name}
          value={inputValue}
          type={type}
          error={error ? true : false}
          helperText={helperText}
          label={props}
          onChange={(e) => {
            setInputValue(e.target.value)
            if (onChange) onChange(e)
          }}
        >
          {data?.map((item) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <p
          style={{color: 'red',}}
        >
          {helperText}
        </p>
      </FormControl>
    </div>
  );
}
