import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import Selects from '../../../../components/Select';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms(data) {
  const [instituicao, setInstituicao] = useState('');
  const [sigla, setSigla] = useState('');
  const [estado, setEstado] = useState('');
  const [estados, setEstados] = useState([]);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (data.row) {
      setInstituicao(data.row.instituicao);
      setEstados(data.row.estado);
      setSigla(data.row.sigla);
    }

    BuscarEstados();
  }, [data]);

  const BuscarEstados = () => {
    api
      .get('/estado/getall')
      .then(function (res) {
        let selectEstados = [];
        res.data.forEach((item) => {
          selectEstados.push({ value: item.id, label: item.estado });
        });

        setEstados(selectEstados);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Cadastrar = () => {
    api
      .post('/instituicao/create/', {
        estado: parseInt(estado),
        instituicao: instituicao,
        sigla: sigla,
      })
      .then(function () {
        toast.success('Instituição cadastrada com sucesso!');
        setTimeout(function () {
          Navigate('/instituicao/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao cadastrar instituição!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/instituicao/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cadastrar Instituição" />
      

      <div className="Box-Body">
        <Selects
          props={'Estados'}
          data={estados}
          onChange={(e) => setEstado(e.target.value)}
        />
        <Input
          title={'Instituição'}
          value={instituicao}
          onChange={(e) => setInstituicao(e.target.value)}
        />
        <Input
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
        />
        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Cadastrar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
