import React, { useEffect } from 'react';
import './index.css';
import ImageAvatars from '../Avatar/index';
//import BasicPopover from '../IconAlerta/index';
import IconMenu from '../IconMenu/index';
import api from '../../services/api';
import { useState } from 'react';
import pmmt from '../../assets/pmmt.jpeg';


export default function Header() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    api
      .get('/usuario/getMyUser')
      .then(function (res) {
        setUser(res.data[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <div className="conteiner">
      <div className="img-logo">
        <img
          src={pmmt}
          alt="Logo"
        />
      </div>

      <div className="img-avatar">
        <div className="img-avatar-content">
          <ImageAvatars />
          <h4 className='UserName'>{user.nome?user.nome.toUpperCase() : ''}</h4>
          <IconMenu />
        </div>
      </div>
    </div>
  );
}
