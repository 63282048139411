import {create} from 'zustand';

const useUnidade = create((set) => ({
  unidadeId: '',
  setUnidadeId: (id) => set(() => ({
    unidadeId
      : id
  })),
}));

export default useUnidade;