import {
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import api from '../../../../services/api';
import useGrupo from '../../../../components/zustand/grupoState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alerta from '../../../../components/Alerta';
import { toast } from 'react-toastify';
export default function Estados() {
  const [busca, setbusca] = useState([]);
  const Navigate = useNavigate();
  const setGrupoId = useGrupo((state) => state.setGrupoId);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const BuscarGrupo = () => {
    api
      .get(`/grupo/paginado?page=${page || 1}`)
      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BuscarGrupo();
  }, [page]);

  const Excluir = (id) => {
    api
      .post('/grupo/delete/',{params:{id:id}})
      .then(function (res) {
        BuscarGrupo();
        toast.success(res.data.message || 'Grupo excluído com sucesso!');
      })
      .catch(function (error) {
        toast.error(error.response.data.error || 'Erro ao excluir grupo!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />

      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Grupos" />

      <div className="Component-Box-Buttons">
        <Buttons
          onClick={() => {
            Navigate('/grupo/create/');
          }}
          title={'Adicionar novo grupo'}
        />
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Unidade</h3>
              </TableCell>
              <TableCell />
              <TableCell>
                <h3>Grupo</h3>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.unidade}
                      </TableCell>
                      <TableCell />
                      <TableCell>{row.grupo}</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            setGrupoId(row.id);
                            Navigate('/grupo/update');
                          }}
                          title={'Alterar'}
                        />
                      </TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => Excluir(row.id)}
                          title={'Excluir'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
