import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DescriptionIcon from '@mui/icons-material/Description';

const ItensCadastro = [
  {
    name: 'Usuários',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/usuarios/list',
    icon: <PersonAddAltIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: 'Perfis',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/perfil/list',
    icon: <SupervisorAccountIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: ' Grupos',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/grupo/list',
    icon: <GroupIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: ' Instituições',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/instituicao/list',
    icon: <HomeWorkIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: ' Estados',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/estado/list',
    icon: <LocationCityIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: ' Unidades',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/unidade/list',
    icon: <ApartmentIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: ' Cidades',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/cidade/list',
    icon: <ApartmentIcon sx={{ cursor: 'pointer' }} />,
  },
 
  {
    name: ' Tipos de Documentos',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/tipo-documento',
    icon: <DescriptionIcon sx={{ cursor: 'pointer' }} />,
  },
 
];

export default ItensCadastro;
