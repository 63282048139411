import {create} from 'zustand';

const useUsuario = create((set) => ({
  usuarioId: '',
  setUsuarioId: (id) => set(() => ({
    usuarioId
      : id
  })),
}));

export default useUsuario;