import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/Buton';
import Selects from '../../../../components/Select';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import useInst from '../../../../components/zustand/instiState';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../components/Input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';
export default function Forms() {
  const [instituicao, setInstituicao] = useState('');
  const [sigla, setSigla] = useState('');
  const [estado, setEstado] = useState('');
  const [estados, setEstados] = useState([]);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();
  const instId = useInst((state) => state.instId);
  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (instId) {
      api.get('/instituicao/getonly/',{params:{id:instId}}).then(function (res) {
        setEstado(res.data.resposta[0].estado);
        setInstituicao(res.data.resposta[0].instituicao);
        setSigla(res.data.resposta[0].sigla);
      });
    }
    BuscarEstados();
  }, []);

  const BuscarEstados = () => {
    api
      .get('/estado/getall')
      .then(function (res) {
        let selectEstados = [];

        res.data.forEach((item) => {
          selectEstados.push({ value: item.id, label: item.estado });
        });

        setEstados(selectEstados);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Alterar = () => {
    api
      .post('/instituicao/update/',{params:{id:instId}}, {
        id: instId,
        estado: estado,
        instituicao: instituicao,
        sigla: sigla,
      })
      .then(function (res) {
        
        toast.success( res.data.message || 'Instituição alterada com sucesso!');
        setTimeout(function () {
          Navigate('/instituicao/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao alterar instituição!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/instituicao/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Alterar Instituição" />
      
      <div className="Box-Body">
        <Selects
          props={'Estados'}
          data={estados}
          defaultValue={estado}
          onChange={(e) => setEstado(e.target.value)}
        />
        <Input
          title={'Instituição'}
          value={instituicao}
          inputProps={{ maxLength: 30 }}
          onChange={(e) => setInstituicao(e.target.value)}
        />
        <Input
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
          inputProps={{ maxLength: 10 }}
        />
        <div className="Button">
          <Buttons
            title={'Alterar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Alterar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
