import {create} from 'zustand';

const useInst = create((set) => ({
  instId: '',
  setInstId: (id) => set(() => ({
    instId
      : id
  })),
}));

export default useInst;