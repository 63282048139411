import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';


export default function Mask({ title, value, onChange, mask, type, error, helperText, name, disabled }) {
  Mask.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    mask: PropTypes.string.isRequired,
    type: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };



  return (
    <>
    
    <InputMask mask={mask} value={value} onChange={onChange} 
      disabled={disabled}
    >
      <TextField
        style={{ width: '100%' }}
        label={title}
        name={name}
        type={type}
        error={error ? true : false}
        helperText={helperText}
      />
    </InputMask>
    </>

  );
}
