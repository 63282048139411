import React from 'react';
import { useFormik } from 'formik';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Input from '../../../../../components/Input';
import Buttons from '../../../../../components/Buton';

// eslint-disable-next-line react/prop-types, no-unused-vars
const ChangePasswordModal = ({ open, onClose, onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: (values) => {
      const errors = {};

      if (!values.password) {
        errors.password = 'Campo obrigatório';
      }

      if (!values.confirmPassword) {
        errors.confirmPassword = 'Campo obrigatório';
      } else if (values.confirmPassword !== values.password) {
        errors.confirmPassword = 'Senhas não coincidem';
      }

      return errors;
    },
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
          Alterar Senha
        </h3>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Input
            title={'Senha'}
            type="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.errors.password && formik.touched.password}
            helperText={
              formik.errors.password &&
              formik.touched.password &&
              formik.errors.password
            }
          />
          <Input
            title={'Confirmar Senha'}
            type="password"
            name="confirmPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={
              formik.errors.confirmPassword && formik.touched.confirmPassword
            }
            helperText={
              formik.errors.confirmPassword &&
              formik.touched.confirmPassword &&
              formik.errors.confirmPassword
            }
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Buttons
            title="Salvar nova senha"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={formik.handleSubmit}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
