import React, { useContext, useEffect, useCallback, useState } from 'react';
import './style.css';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Brasao from '../../assets/brasaoDaci.gif';
import api from '../../services/api';
import md5 from 'md5';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import Alerta from '../../components/Alerta';
export default function Login() {
  const { setAuthenticated, setToken } = useContext(AuthContext);
  const [capValue, setCapValue] = useState(null);

  const navigate = useNavigate();

  const resetData = () => {
    setAuthenticated(false);
    setToken(null);
  };

  useEffect(() => {
    resetData();
  }, []);

  let send = useCallback((event, capValue) => {
    if (event && event.key !== 'Enter' && event.type !== 'click') return;

    event.preventDefault();
    const dados = {
      login: document.getElementById('fieldLogin').value,
      pwd: md5(document.getElementById('fieldPwd').value),
    };

    api
      .post('/login', dados, { headers: { capvalue: capValue } })
      .then((r) => {
        setAuthenticated(true);
        setToken(r.data.token);
        toast.success(r.data.response);
        setTimeout(function () {
          navigate('/home');
        }, 700);
      })
      .catch((e) => {
        toast.error(e.response.data);
        resetData();
      });
  }, []);

  return (
    <>
      <Alerta />
      <div id="id_container_page_1">
        <div id="id_container_form_1">
          <div id="id_brasao_l" className="divCenter_1">
            <img src={Brasao} alt="some value" width="30%" />
          </div>

          <div id="id_containerBox_1">
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="containerField10">
                <div className="divCenter_1">
                  <TextField
                    required
                    id="fieldLogin"
                    label="Login"
                    defaultValue=""
                    onKeyUp={(e) => {
                      if (e.key === 'Enter')
                        document.getElementById('fieldPwd').focus();
                    }}
                  />
                </div>

                <div className="divCenter_1">
                  <TextField
                    required
                    id="fieldPwd"
                    label="Password"
                    type={'password'}
                    defaultValue=""
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') send(e, capValue);
                    }}
                  />
                </div>
                <div className="g-recaptcha">
                  <ReCAPTCHA
                    size="compact"
                    sitekey="6Le6WdEoAAAAALMoTbnIUsaNaKS3Wljagm4GRIEZ"
                    onChange={(val) => {
                      setCapValue(val);
                    }}
                  />
                </div>
              </div>
              <div id="id_button_1" className="divCenter_1">
                <Button
                  disabled={!capValue}
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    send(e, capValue);
                  }}
                >
                  Enviar
                </Button>
              </div>

              <div id="id_lock_1" className="divCenter_1">
                <LockIcon fontSize="large" />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
