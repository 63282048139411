import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import useUnidade from '../../../../components/zustand/unidadeState';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function alterarTipoDocumento() {
  const [nome, setNome] = useState('');
  const navigate = useNavigate();
  const unidadeId = useUnidade((state) => state.unidadeId);

  const Alterar = () => {
    api
      .post('/tipoDocumento/update/',{params:{id:unidadeId}}, {
        id: unidadeId,
        nome: nome,
      })
      .then(() => {
        toast.success('Documento alterado com sucesso!');
        setTimeout(function () {
          navigate('/tipo-documento');
        }, 1500);
      })
      .catch(() => {
        toast.error('Erro ao alterar documento!');
      });
  };

  useEffect(() => {

    if(unidadeId){
        api.get('/tipoDocumento/buscarPorId/',{params:{id:unidadeId}}).then(function (res) {
            setNome(res.data.resposta[0].nome);
        });
        }
    }, []);


  return (
    <>
      <Alerta />

      <div className="Component-Box">
        <div className="Component-Box-Buttons-Volta">
          <Buttons
            onClick={() => {
              window.history.back();
            }}
            title={<ArrowBackIcon />}
          />
        </div>
        <ScreenLogo title="Alterar Documento" />
        <div className="Box-Body">
          <Input
            title={'Nome da Unidade'}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <div className="Button">
            <Buttons title={'Alterar'} onClick={Alterar} />
          </div>
        </div>
      </div>
    </>
  );
}
