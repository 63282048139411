import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export default function Buttons({ id, title, onClick, disabled, type, startIcon }) {
  Buttons.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    startIcon: PropTypes.string
  };
  return (
    <Stack spacing={4} direction="row">
      <Button
        type={type}
        variant="contained"
        onClick={onClick}
        title={title}
        disabled={disabled}
        startIcon={startIcon}
        id={id}
        style={{height:'50px', textAlign:'left', margin: '5px'}}
      >
        {title}
      </Button>
    </Stack>
  );
}
