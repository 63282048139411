import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Mask from '../../../../components/InputMask';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas/';
import api from '../../../../services/api';
import './style.scss';
import { Form, Formik } from 'formik/dist';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Selects from '../../../../components/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Usuarios() {
  const [double, setDouble] = useState(false);
  const navigate = useNavigate();
  const [unidades, setUnidades] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [perfis, setPerfis] = useState([]);

  useEffect(() => {
    BuscarUnidade();
    BuscarGrupo();
    BuscarPerfil();
  }, []);

  const BuscarUnidade = () => {
    api
      .get('/unidade/getall')
      .then(function (res) {
        let selectUnidades = [];
        res.data.forEach((item) => {
          selectUnidades.push({ value: item.id, label: item.unidade });
        });
        setUnidades(selectUnidades);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BuscarGrupo = () => {
    api
      .get('/grupo/getall')
      .then(function (res) {
        let selectGrupos = [];
        res.data.forEach((item) => {
          selectGrupos.push({ value: item.id, label: item.grupo });
        });
        setGrupos(selectGrupos);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BuscarPerfil = async () => {
    await api
      .get('/perfil/getall')
      .then(function (res) {
        let selectPerfil = [];
        res.data.forEach((item) => {
          selectPerfil.push({ value: item.id, label: item.perfil });
        });
        setPerfis(selectPerfil);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const schema = Yup.object().shape({
    nome: Yup.string().required('Nome é obrigatório '),
    cpf: Yup.string().required('CPF é obrigatório'),
    telefone: Yup.string().required('Telefone é obrigatório'),
    email: Yup.string().required('Email é obrigatório'),
    unidade: Yup.string().required('Unidade é obrigatório'),
    grupo: Yup.string().required('Grupo é obrigatório'),
    perfil: Yup.string().required('Perfil é obrigatório'),
    password: Yup.string().required('Senha é Obrigatório'),
  });

  const initialValues = {
    nome: '',
    cpf: '',
    telefone: '',
    email: '',
    unidade: '',
    grupo: '',
    perfil: '',
    password: '',
  };

  const handleSubmitLogin = async (e) => {
    api
      .post('/usuario/create', {
        nome: e.nome,
        cpf: e.cpf.replace(/[^0-9]/g, ''),
        telefone: e.telefone,
        email: e.email,
        unidade: e.unidade,
        grupo: e.grupo,
        perfil: e.perfil,
        password: e.password,
      })
      .then(function (res) {
        toast.success(res.data.message || 'Usuário cadastrado com sucesso!');
        setTimeout(function () {
          navigate('/usuarios/list');
        }, 1500);
      })
      .catch(function (error) {
        toast.error(error.response.data.error || 'Erro ao cadastrar usuário!');
        setDouble(false);
      });
  };

  return (
    <>
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <div className="box">
        <div className="box-img">
          <ScreenLogo title="Usuarios" />
        </div>
        <Formik
          onSubmit={handleSubmitLogin}
          validationSchema={schema}
          initialValues={initialValues}
        >
          {({ errors, handleChange, touched, values }) => {
            return (
              <Form className="form" noValidate style={{ width: '100%' }}>
                <div className="box-body">
                  <Input
                    title={'Nome Completo'}
                    name="nome"
                    onChange={handleChange}
                    value={values.nome}
                    error={errors.nome && touched.nome}
                    helperText={errors.nome && touched.nome && errors.nome}
                  />

                  <Mask
                    name="cpf"
                    title={'CPF'}
                    mask="999.999.999-99"
                    onChange={handleChange}
                    value={values.cpf}
                    error={errors.cpf && touched.cpf}
                    helperText={errors.cpf && touched.cpf && errors.cpf}
                  />

                  <Mask
                    name="telefone"
                    title={'Telefone'}
                    mask="(99) 99999-9999"
                    onChange={handleChange}
                    value={values.telefone}
                    error={errors.telefone && touched.telefone}
                    helperText={
                      errors.telefone && touched.telefone && errors.telefone
                    }
                  />

                  <Input
                    title="Email"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && errors.email}
                  />

                  <Selects
                    props={'Unidade'}
                    data={unidades}
                    name="unidade"
                    onChange={handleChange}
                    value={values.unidade}
                    error={errors.unidade && touched.unidade}
                    helperText={
                      errors.unidade && touched.unidade && errors.unidade
                    }
                  />

                  <Selects
                    props={'Grupo'}
                    data={grupos}
                    name="grupo"
                    onChange={handleChange}
                    value={values.grupo}
                    error={errors.grupo && touched.grupo}
                    helperText={errors.grupo && touched.grupo && errors.grupo}
                  />

                  <Selects
                    props={'Perfil '}
                    data={perfis}
                    name="perfil"
                    onChange={handleChange}
                    value={values.perfil}
                    error={errors.perfil && touched.perfil}
                    helperText={
                      errors.perfil && touched.perfil && errors.perfil
                    }
                  />

                  <Input
                    title={'Senha'}
                    type="password"
                    name="password"
                    onChange={(e) => handleChange(e)}
                    value={values.password}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                  />
                </div>
                <div className="box-footer">
                  <Buttons title="Salvar" disabled={double} type="submit" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
