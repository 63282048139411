import React from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import ItensMenu from './itensMenu';

export default function Menu() {
  const Navigate = useNavigate();

  return (
    <div className="idContainerPageMenu">
      <div className="idContainerMenu">
        {ItensMenu
          ? ItensMenu.map((e, index) => {
              return (
                <div
                  className="idContainerIconMenu10"
                  key={index}
                  onClick={() => Navigate(`${e.destiny}`)}
                >
                  {e.icon}
                  <div className="idContainerNameIconMenu">{e.name}</div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
