import {
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import api from '../../../../services/api';
import useEstado from '../../../../components/zustand/estadoState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Estados() {
  const [busca, setbusca] = useState([]);
  const Navigate = useNavigate();
  const setEstadoId = useEstado((state) => state.setEstadoId);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const BuscarEstados = () => {
    api
      .get(`/estado/paginado?page=${page || 1}`)
      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BuscarEstados();
  }, [page]);

  const Excluir = (id) => {
    api
      .post('/estado/delete/',{params:{id:id}})
      .then(function (res) {
        BuscarEstados();
        toast.success( res.data.message || 'Estado excluído com sucesso!');
      })
      .catch(function (error) {
        toast.error(error.response.data.error || 'Erro ao excluir estado!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />

      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Estados" />
     
      <div className="Component-Box-Buttons">
        <Buttons
          onClick={() => {
            Navigate('/estado/create');
          }}
          title={'Adicionar novo estado'}
        />
      </div>
      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Estado</h3>
              </TableCell>
              <TableCell>
                <h3>Sigla</h3>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.estado}</TableCell>
                      <TableCell>{row.sigla}</TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            setEstadoId(row.id);
                            Navigate(`/estado/update/`);
                          }}
                          title={'Alterar'}
                        />
                      </TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => Excluir(row.id)}
                          title={'Excluir '}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
