import {
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/api';
import useUnidade from '../../../../components/zustand/unidadeState';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function TipoDocumento() {
  const setUnidadeId = useUnidade((state) => state.setUnidadeId);

  const navigate = useNavigate();
  const [busca, setbusca] = React.useState([]);

  const getTipoDocumento = () => {
    api
      .get('/tipoDocumento/getall')
      .then((response) => {
        setbusca(response.data.resposta);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Excluir = (id) => {
    api
      .post('/tipoDocumento/delete/',{params:{id:id}})
      .then((response) => {
        getTipoDocumento();
        setbusca(response.data.resposta);
        toast.success(response.data.message || 'Excluído com sucesso');
        setTimeout(function () {
        }, 3000);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao excluir');
      });
  };

  useEffect(() => {
    getTipoDocumento();
  }, []);

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            window.history.back();
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Documentos" />

      <div
        className="Component-Box-Buttons"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <div className="toolbar_10_class">
          <Buttons
            onClick={() => {
              navigate('/tipo-cadastrar');
            }}
            title={'Novo Tipo de documento'}
          />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Nome</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.nome}</TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            setUnidadeId(row.id);
                            navigate('/tipo-alterar');
                          }}
                          title={'Alterar'}
                        />
                      </TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            Excluir(row.id);
                          }}
                          title={'Excluir'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={null}
          page={null}
          onChange={null}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
