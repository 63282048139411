import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import Buttons from '../../../../components/Buton/index';
import { useNavigate } from 'react-router-dom';
import ScreenLogo from '../../../../components/LogoTelas/';
import './style.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useUsuario from '../../../../components/zustand/usuarioState';
import { Pagination, PaginationItem } from '@mui/material';
import Alerta from '../../../../components/Alerta';

export default function AcccessibleTable() {
  const [busca, setbusca] = useState([]);
  const setUsuarioId = useUsuario((state) => state.setUsuarioId);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const navigate = useNavigate();
  const BuscarUnidade = () => {
    api
      .get(`/usuario/paginado?page=${page || 1} `)
      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.totalPages);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BuscarUnidade();
  }, [page]);

  return (
    <>
    <Alerta />
      <div className="Component-Box">
        <div className="Component-Box-Buttons-Volta">
          <Buttons
            onClick={() => {
              history.back();
            }}
            title={<ArrowBackIcon />}
          />
        </div>
        <ScreenLogo title="Usuarios" />
        <div className="Component-Box-Buttons">
          <Buttons
            onClick={() => {
              navigate('/usuario/create');
            }}
            title={'Adicionar Usuário'}
          />
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableRow>
              <TableCell>
                <h3>Nome</h3>
              </TableCell>

              <TableCell>
                <h3>CPF</h3>
              </TableCell>

              <TableCell>
                <h3>Telefone</h3>
              </TableCell>

              <TableCell>
                <h3>Email</h3>
              </TableCell>

              <TableCell>
                <h3>Unidade</h3>
              </TableCell>

              <TableCell>
                <h3>Grupo</h3>
              </TableCell>

              <TableCell>
                <h3>Perfil</h3>
              </TableCell>
            </TableRow>

            <TableBody>
              {Array.isArray(busca) && busca.length > 0
                ? busca.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{row.nome}</TableCell>
                        <TableCell>{row.cpf}</TableCell>
                        <TableCell>{row.telefone}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.unidade}</TableCell>
                        <TableCell>{row.grupo}</TableCell>
                        <TableCell>{row.perfil}</TableCell>
                        <TableCell>
                          <Buttons
                            onClick={() => {
                              setUsuarioId(row.id);
                              navigate(`/usuario/alterar`);
                            }}
                            title={'Alterar'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="Pagination">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            renderItem={(item) => <PaginationItem {...item} />}
          />
        </div>
      </div>
    </>
  );
}
