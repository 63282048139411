import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import Selects from '../../../../components/Select';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms(res) {
  const [grupo, setGrupo] = useState('');
  const [unidade, setUnidade] = useState('');
  const [unidades, setUnidades] = useState([]);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (res.row) {
      setUnidade(res.row.unidade);
      setGrupo(res.row.grupo);
    }
    BuscarUnidade();
  }, []);

  const BuscarUnidade = () => {
    api
      .get('/unidade/getall')
      .then(function (res) {
        let selectUnidades = [];
        res.data.forEach((item) => {
          selectUnidades.push({ value: item.id, label: item.unidade });
        });
        setUnidades(selectUnidades);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Cadastrar = () => {
    api
      .post('/grupo/create/', {
        unidade: unidade,
        grupo: grupo,
      })
      .then(function () {
        toast.success('Grupo cadastrado com sucesso!');
        setTimeout(function () {
          Navigate('/grupo/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao cadastrar grupo!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/grupo/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cadastrar Grupos" />
      <div className="Box-Body">
        <Selects
          props={'Unidades'}
          data={unidades}
          onChange={(e) => setUnidade(e.target.value)}
        />
        <TextField
          title={'grupo'}
          value={grupo}
          onChange={(e) => setGrupo(e.target.value)}
          label="Grupo"
          style={{ width: '100%' }}
        />
        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Cadastrar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
