import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './pages/Login/index';
import Menu from './pages/Dashboard/menu/';
import Header from './components/Header/Header';
// import Sidebar from './pages/Dashboard/sidebar/sidebar';

import UnidadeList from './pages/page/Unidade/List/';
import UnidadeCreate from './pages/page/Unidade/Create/';
import UnidadeEdit from './pages/page/Unidade/Alterar/';
import EstadoList from './pages/page/Estados/List/';
import EstadoCreate from './pages/page/Estados/Create/';
import EstadoUpdate from './pages/page/Estados/Alterar/';
import GrupoList from './pages/page/Grupos/List/';
import GrupoCreate from './pages/page/Grupos/Create/';
import GrupoUpdate from './pages/page/Grupos/Alterar/';
import InstituicaoList from './pages/page/Instituicao/List/';
import InstituicaoCreate from './pages/page/Instituicao/Create/';
import InstituicaoUpdate from './pages/page/Instituicao/Alterar/';
import CidadesList from './pages/page/Cidade/List/';
import CidadesCreate from './pages/page/Cidade/Create/';
import CidadesUpdate from './pages/page/Cidade/Alterar/';
import Usuario from './pages/page/Usuario/Create';
import UsuariosList from './pages/page/Usuario/List';
import AlterarPerfil from './pages/page/Usuario/Alterar';
import AlterarUsuario from './pages/page/Usuario/AlterarUsuarios';
import PerfilList from './pages/page/Perfil/List/';
import PerfilCreate from './pages/page/Perfil/Create/';
import PerfilUpdate from './pages/page/Perfil/Alterar/';
// import DocumentosCreate from './pages/page/Documentos/Create/';
import Sipom from './pages/page/Sipom/';
import NumerarDoc from './pages/page/Documentos/NumerarDoc';
import DocList from './pages/page/Documentos/ListNumDoc';
import TipoDocumento from './pages/page/TipoDocumento/Lista';
import TipoDocumentoCadastrar from './pages/page/TipoDocumento/Create/cadastrar';
import TipoDocumentoAlterar from './pages/page/TipoDocumento/Alterar/Alterar';
import Permissoes from './pages/page/Perfil/Permissoes';
import UploadPdfComponent from './pages/page/PdfExtractor/Create';
import TabelaItens from './pages/page/PdfExtractor/Lista';
import PDFViewer from './pages/page/PdfExtractor/modalPdf';
import Cadastro from './pages/Dashboard/menu/cadastro/';

// import Estatisca from './pages/page/Estatistica/index';
// import ListarDoc from './pages/page/Documentos/List/';

const PrivateRoute = ({ children, ...rest }) => {
  const isPrivate = rest.isPrivate;
  let loggedIn = document.cookie.split('=');
  let token = loggedIn ? loggedIn[loggedIn.indexOf('loggedIn') + 1] : false;

  if (isPrivate && token != false) {
    return (
      <>
        <div id="containerHeaderAll1">
          <Header />
        </div>
        <div id="containerPageAll1">
          <div id="containerSidebarAll1">{/* <Sidebar /> */}</div>
          <div id="containerFramePageAll1">{children}</div>
        </div>
      </>
    );
  } else {
    return <Navigate to="/" />;
  }
};

PrivateRoute.propTypes = { children: PropTypes.element.isRequired };

export default function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/cadastro"
          element={
            <PrivateRoute isPrivate>
              <Cadastro />
            </PrivateRoute>
          }
        />
        <Route
          path="/home"
          element={
            <PrivateRoute isPrivate>
              <Menu />
            </PrivateRoute>
          }
        />
        <Route
          path="/unidade/list"
          element={
            <PrivateRoute isPrivate>
              <UnidadeList />
            </PrivateRoute>
          }
        />
        <Route
          path="/unidade/create"
          element={
            <PrivateRoute isPrivate>
              <UnidadeCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/unidade/update/"
          element={
            <PrivateRoute isPrivate>
              <UnidadeEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/estado/list"
          element={
            <PrivateRoute isPrivate>
              <EstadoList />
            </PrivateRoute>
          }
        />
        <Route
          path="/estado/create/"
          element={
            <PrivateRoute isPrivate>
              <EstadoCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/estado/update/"
          element={
            <PrivateRoute isPrivate>
              <EstadoUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/grupo/list"
          element={
            <PrivateRoute isPrivate>
              <GrupoList />
            </PrivateRoute>
          }
        />
        <Route
          path="/grupo/create/"
          element={
            <PrivateRoute isPrivate>
              <GrupoCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/grupo/update/"
          element={
            <PrivateRoute isPrivate>
              <GrupoUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/instituicao/list"
          element={
            <PrivateRoute isPrivate>
              <InstituicaoList />
            </PrivateRoute>
          }
        />
        <Route
          path="/instituicao/create/"
          element={
            <PrivateRoute isPrivate>
              <InstituicaoCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/instituicao/update/"
          element={
            <PrivateRoute isPrivate>
              <InstituicaoUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/cidade/list/"
          element={
            <PrivateRoute isPrivate>
              <CidadesList />
            </PrivateRoute>
          }
        />
        <Route
          path="/cidade/create/"
          element={
            <PrivateRoute isPrivate>
              <CidadesCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/cidade/update/"
          element={
            <PrivateRoute isPrivate>
              <CidadesUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/perfil/list/"
          element={
            <PrivateRoute isPrivate>
              <PerfilList />
            </PrivateRoute>
          }
        />
        <Route
          path="/perfil/create/"
          element={
            <PrivateRoute isPrivate>
              <PerfilCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/perfil/update/"
          element={
            <PrivateRoute isPrivate>
              <PerfilUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/usuario/create"
          element={
            <PrivateRoute isPrivate>
              <Usuario />
            </PrivateRoute>
          }
        />
        <Route
          path="/usuarios/list"
          element={
            <PrivateRoute isPrivate>
              <UsuariosList />
            </PrivateRoute>
          }
        />
        <Route
          path="/alterar/perfil"
          element={
            <PrivateRoute isPrivate>
              <AlterarPerfil />
            </PrivateRoute>
          }
        />
        <Route
          path="/usuario/alterar"
          element={
            <PrivateRoute isPrivate>
              <AlterarUsuario />
            </PrivateRoute>
          }
        />
        <Route
          path="/documentos/list"
          element={
            <PrivateRoute isPrivate>
              <DocList />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/documentos/create"
          element={
            <PrivateRoute isPrivate>
              <DocumentosCreate />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/numerar-documento"
          element={
            <PrivateRoute isPrivate>
              <NumerarDoc />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/listar/produtividade"
          element={
            <PrivateRoute isPrivate>
              <ListarDoc />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/Sipom"
          element={
            <PrivateRoute isPrivate>
              <Sipom />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/estatisca"
          element={
            <PrivateRoute isPrivate>
              <Estatisca />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/tipo-documento"
          element={
            <PrivateRoute isPrivate>
              <TipoDocumento />
            </PrivateRoute>
          }
        />

        <Route
          path="/tipo-cadastrar"
          element={
            <PrivateRoute isPrivate>
              <TipoDocumentoCadastrar />
            </PrivateRoute>
          }
        />
        <Route
          path="/tipo-alterar"
          element={
            <PrivateRoute isPrivate>
              <TipoDocumentoAlterar />
            </PrivateRoute>
          }
        />

        <Route
          path="/permissoes"
          element={
            <PrivateRoute isPrivate>
              <Permissoes />
            </PrivateRoute>
          }
        />

        <Route
          path="/upload-pdf"
          element={
            <PrivateRoute isPrivate>
              <UploadPdfComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/listar-files"
          element={
            <PrivateRoute isPrivate>
              <TabelaItens />
            </PrivateRoute>
          }
        />
        <Route
          path="/teste"
          element={
            <PrivateRoute isPrivate>
              <PDFViewer />
            </PrivateRoute>
          }
        />

        <Route
          path="/permissoes"
          element={
            <PrivateRoute isPrivate>
              <Permissoes />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
