import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import Input from '../../../../components/Input';
import api from '../../../../services/api';
import useEstado from '../../../../components/zustand/estadoState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';
export default function Forms() {
  const estadoId = useEstado((state) => state.estadoId);
  const Navigate = useNavigate();

  const [estado, setEstado] = useState('');
  const [sigla, setSigla] = useState('');

  useEffect(() => {
    if (estadoId) {
      api.get('/estado/readonly/',{params:{id:estadoId}}).then(function (res) {
        setEstado(res.data.resposta[0].estado);
        setSigla(res.data.resposta[0].sigla);
      });
    }
  }, []);

  const Alterar = () => {
    api
      .post('/estado/update/',{params:{id:estadoId}}, {
        id: estadoId,
        estado: estado,
        sigla: sigla,
      })
      .then(function () {
        toast.success('Estado alterado com sucesso!');
        setTimeout(function () {
          Navigate('/estado/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao alterar estado!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/estado/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Alterar Estados" />

      <div className="Box-Body">
        <Input
          title={'Estado'}
          value={estado}
          onChange={(e) => {
            setEstado(e.target.value);
          }}
        />
        <TextField
          style={{ width: '100%' }}
          label="Sigla"
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
          inputProps={{ maxLength: 5 }}
        />
        <div className="Button">
          <Buttons title={'Alterar'} onClick={Alterar} />
        </div>
      </div>
    </div>
  );
}
