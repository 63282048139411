import React from 'react';
import './global.css';
import RoutesFunction from './routes';
import AuthProvider from './context/auth';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RoutesFunction />
      </AuthProvider>
    </div>
  );
}

export default App;
