import api from '../../../../services/api';
import {
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Input from '../../../../components/Input';
import SearchIcon from '@mui/icons-material/Search';
import './style.scss';
import BasicModal from '../../Estatistica/Modal';

export default function NumerarDoc() {
  const [busca, setbusca] = useState([]);
  const Navigate = useNavigate();
  const [search, setSearch] = React.useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const searchLower = search.toLowerCase();
  const filter = () => {
    if (search) {
      const newData = busca.filter((item) => {
        const itemData = item.nome_usuario.toLowerCase();
        const itemData2 = item.nome.toLowerCase();
        const itemData3 = item.unidade.toLowerCase();
        const itemData4 = item.destino_doc.toLowerCase();
        const itemData5 = item.data.toLowerCase();
        const itemData6 = item.numero_doc.toLowerCase();
        const textData = searchLower;
        return (
          itemData.indexOf(textData) > -1 ||
          itemData2.indexOf(textData) > -1 ||
          itemData3.indexOf(textData) > -1 ||
          itemData4.indexOf(textData) > -1 ||
          itemData5.indexOf(textData) > -1 ||
          itemData6.indexOf(textData) > -1
        );
      });
      setbusca(newData);
    }
  };

  const BuscaDocumento = () => {
    api
      .get(`/documento/getall?page=${page || 1}`)
      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BuscaDocumento();
    filter();
  }, [page]);

  return (
    <div className="Component-Box">
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/Sipom');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Documentos" />
      <div
        className="Component-Box-Buttons"
        style={{ display: 'flex', justifyContent: 'space-between', position:'relative' }}
      >
        <div className="toolbar_10_class">
          <Buttons
            onClick={() => {
              Navigate('/numerar-documento');
            }}
            title={'Adicionar novo documento'}
          />

          <BasicModal />
        
        <div className="Component-Box-Search">
          <Input
            onChange={(e) => {
              if (e.target.value === '') {
                BuscaDocumento();
              }
              setSearch(e.target.value);
              filter();
            }}
            title={'Pesquisar'}
            type={'text'}
            value={search}
            icon={<SearchIcon />}
          />
        </div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Nome</h3>
              </TableCell>
              <TableCell>
                <h3>Tipo do Documento</h3>
              </TableCell>
              <TableCell>
                <h3>Unidade de Origem</h3>
              </TableCell>
              <TableCell>
                <h3>Unidade de Destino</h3>
              </TableCell>
              <TableCell>
                <h3>Data</h3>
              </TableCell>
              <TableCell>
                <h3>Codigo Documento</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.nome_usuario}</TableCell>
                      <TableCell>{row.nome}</TableCell>
                      <TableCell> {row.unidade} </TableCell>
                      <TableCell> {row.destino_doc} </TableCell>
                      <TableCell> {row.data} </TableCell>
                      <TableCell> {row.numero_doc} </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
