import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import api from '../../../../services/api';
import ModalPDF from '../modalPdf';
import ScreenLogo from '../../../../components/LogoTelas';
import Buttons from '../../../../components/Buton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default function TipoDocumento() {
  const [busca, setBusca] = useState([]);
  const Navigate = useNavigate();
  
  const getBuscarFiles = () => {
    api
      .get('/getAll')
      .then((response) => {
        setBusca(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBuscarFiles();
  }, []);

  return (
    <div className="Component-Box">

<div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Arquivos PDF" />
     
      <div className="Component-Box-Buttons">
        <Buttons
          onClick={() => {
            Navigate('/upload-pdf');
          }}
          title={'Adicionar nova arquivo PDF'}
        />
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>Nome</h3>
              </TableCell>
              <TableCell>
                <h3>Extensão</h3>
              </TableCell>
              <TableCell>
                <h3>Armário</h3>
              </TableCell>
              <TableCell>
                <h3>Prateleira</h3>
              </TableCell>
              <TableCell>
                <h3>Caixa</h3>
              </TableCell>
              <TableCell>
                <h3>Pasta</h3>
              </TableCell>
              <TableCell>
                <h3>Ações</h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0 ? (
              busca.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.nome}</TableCell>
                    <TableCell>{row.extensao}</TableCell>
                    <TableCell>{row.armario}</TableCell>
                    <TableCell>{row.prateleira}</TableCell>
                    <TableCell>{row.caixa}</TableCell>
                    <TableCell>{row.pasta}</TableCell>
                    <TableCell>
                      <ModalPDF rowId={row.id}/>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={7}>Nenhum dado encontrado</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
