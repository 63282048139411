import React, { useEffect, useState } from 'react';
import ScreenLogo from '../../../../components/LogoTelas';
import Input from '../../../../components/Input';
import Mask from '../../../../components/InputMask';
import { TextField } from '@mui/material';
import api from '../../../../services/api';
import Buttons from '../../../../components/Buton';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Selects from '../../../../components/Select';
import './style.scss';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';
export default function Documentos() {
  const [user, setUser] = React.useState([]);

  const [data, setData] = useState(new Date().toLocaleDateString('en-GB'));
  const [observacao, setObservacao] = useState('');
  const [complemento, setComplemento] = useState('');
  const [unidadeOrigem, setUnidadeOrigem] = useState('');
  const [unidadeDestino, setUnidadeDestino] = useState('');
  const [tipodocumento, setTipoDocumento] = useState('');
  const [assunto, setAssunto] = useState('');
  const [tipodocumentos, setTipoDocumentos] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState('');

  const generateCode = async (unidadeId) => {
    const unidade = await getUnidade(unidadeId);
    const instituicao = await getInstituicao(unidade.instituicao);
    const ano = new Date().getFullYear();

    const docNumber = await lastDocNumber(ano, tipodocumento);
    const unidadeSigla = unidade?.sigla;
    const instituicaoSigla = instituicao?.sigla;
    const anoDoc = ano;

    const code = `${docNumber}/${unidadeSigla}/${instituicaoSigla}/${anoDoc}`;
    setCode(code);
    return code;
  };

  const getUnidade = async (unidadeorigem) => {
    const result = await api.get('/unidade/getonly/',{params:{id:unidadeorigem}});
    return result?.data || null;
  };

  const getInstituicao = async (instId) => {
    const result = await api.get('/instituicao/getonly/', {params:{id:instId}});
    return result?.data?.resposta[0] || null;
  };

  const lastDocNumber = async (ano, tipo) => {
    const result = await api.get('/documento/getUltimo/',{params:{ano,tipo}});

    const numero = Number(result.data[0].codigo) + 1;
    return numero;
  };

  useEffect(() => {
    api
      .get('/usuario/getMyUser')
      .then(function (res) {
        setUser(res.data[0]);
      })
      .catch(function (error) {
        console.error(error);
      });
    TipoDocumento();
  }, []);

  const TipoDocumento = () => {
    api
      .get('/tipoDocumento/getall')
      .then(function (res) {
        let selectTipoDocumento = [];
        res.data.resposta.forEach((item) => {
          selectTipoDocumento.push({ value: item.id, label: item.nome });
        });
        setTipoDocumentos(selectTipoDocumento);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BuscarUnidade = () => {
    api
      .get('/unidade/getall')
      .then(function (res) {
        let selectUnidades = [];
        res.data.forEach((item) => {
          selectUnidades.push({
            value: item.id,
            label: item.unidade + ' - ' + item.sigla,
          });
        });
        setUnidades(selectUnidades);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    BuscarUnidade();
  }, []);

  const Cadastrar = async () => {
    try {
      const response = await api.post('/documento/create/', {
        usuario: user.id,
        tipoDocumento: tipodocumento,
        numero_doc: await generateCode(unidadeOrigem),
        data: data,
        unidade: unidadeOrigem,
        destino_doc: unidadeDestino,
        complemento: complemento,
        assunto: assunto,
        obs: observacao,
        codigo: await lastDocNumber(new Date().getFullYear(), tipodocumento),
        ano: new Date().getFullYear(),
      });

      // Se a resposta da API for bem-sucedida, exibe o modal
      if (response.status === 200) {
        setShowModal(true);
        // Restante do seu código de manipulação de sucesso aqui
        toast.success('Documento cadastrado com sucesso!');
      }
    } catch (error) {
      toast.error(error.response.data.error || 'Erro ao cadastrar documento!');
      setDouble(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    Navigate('/documentos/list');
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/documentos/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Numerar Documentos" />

      <div className="box-body">
        <Selects
          props={'Tipo do Documento'}
          data={tipodocumentos}
          onChange={(e) => setTipoDocumento(e.target.value)}
          disabled={double}
        />
        <Selects
          props={'Unidade de Origem'}
          data={unidades}
          onChange={(e) => setUnidadeOrigem(e.target.value)}
          disabled={double}
        />
        <Selects
          props={'Unidade de Destino'}
          data={unidades}
          onChange={(e) => setUnidadeDestino(e.target.value)}
          disabled={double}
        />
        <Mask
          title={'Data'}
          mask={'99/99/9999'}
          value={data}
          onChange={(e) => setData(e.target.value)}
          disabled={double}
        />
        <Input
          title={'complemento'}
          placeholder="complemento"
          value={complemento}
          onChange={(e) => setComplemento(e.target.value)}
          disabled={double}
        />
        <Input
          title={'Assunto'}
          placeholder="Assunto"
          style={{ width: '100%', height: '100px' }}
          value={assunto}
          onChange={(e) => setAssunto(e.target.value)}
          disabled={double}
        />

        <TextField
          title={'Observação'}
          placeholder="Observação"
          style={{ width: '100%', height: '100px' }}
          value={observacao}
          onChange={(e) => setObservacao(e.target.value)}
          disabled={double}
        />

        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Cadastrar();
            }}
          />
        </div>
      </div>
      {showModal && (
        <div className="modal_code">
          <button className="close" onClick={handleCloseModal}>
            &times;
          </button>
          <div className="modal-content">
            <div className="modal-body">
              <p>
                Código: <strong>{code}</strong>
              </p>
            </div>
            <div className="modal-footer">
              <Buttons
                title={<CopyAllIcon />}
                onClick={() => {
                  setShowModal(false);
                  Navigate('/documentos/list');
                  navigator.clipboard.writeText(code);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
