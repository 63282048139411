import {create} from 'zustand';

const useUnidade = create((set) => ({
  cidadeId: '',
  setCidadeId: (id) => set(() => ({
    cidadeId
      : id
  })),
}));

export default useUnidade;