import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Buttons from '../../../../components/Buton';
import api from '../../../../services/api';
import { useEffect } from 'react';
import './style.css';

// eslint-disable-next-line react/prop-types
export default function ModalPDF({ rowId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (rowId) {
      api
        .get('/file/',{params:{id:rowId}}, { responseType: 'arraybuffer' }) // Indicar responseType como 'arraybuffer'
        .then((response) => {
          const arrayBuffer = response.data;
          const base64Data = arrayBufferToBase64(arrayBuffer);
          setFile(base64Data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  return (
    <div>
      <Buttons
        onClick={handleOpen}
        title={'Ver PDF'}
        style={{ height: '100%' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalPdf">
          <button
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              zIndex: '9999',
            }}
            onClick={handleClose}
          >
            Fechar
          </button>

          <a
            href={`data:application/pdf;base64,${file}`}
            download="file.pdf"
            style={{
              padding: '12px 24px',
              backgroundColor: '#007bff',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onClick={handleClose}
          >
            Baixar PDF
          </a>
        </div>
      </Modal>
    </div>
  );
}
