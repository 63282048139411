import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import Selects from '../../../../components/Select';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import useUnidade from '../../../../components/zustand/unidadeState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms() {
  const [instituicao, setInstituicao] = useState('');
  const [unidade, setUnidade] = useState('');
  const [sigla, setSigla] = useState('');
  const [instituicoes, setInstituicoes] = useState([]);
  const unidadeId = useUnidade((state) => state.unidadeId);
  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (unidadeId) {
      api.get('/unidade/getonly/',{params:{id:unidadeId}}).then(function (res) {
        setInstituicao(res.data.instituicao);
        setUnidade(res.data.unidade);
        setSigla(res.data.sigla);
      });
    }

    BuscarInstituicao();
  }, []);

  const BuscarInstituicao = () => {
    api
      .get('/instituicao/getall')
      .then(function (res) {
        let selectInstituicoes = [];

        res.data.resposta.forEach((item) => {
          selectInstituicoes.push({ value: item.id, label: item.instituicao });
        });

        setInstituicoes(selectInstituicoes);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Alterar = () => {
    api
      .post('/unidade/update/',{params:{id:unidadeId}}, {
        id: unidadeId,
        instituicao: instituicao,
        unidade: unidade,
        sigla: sigla,
      })
      .then(function () {
        
        toast.success('Unidade alterada com sucesso!');
        setTimeout(function () {
          Navigate('/unidade/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao alterar unidade!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/unidade/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Alterar Unidade" />

      <div className="Box-Body">
        <Selects
          props={'Instituição'}
          data={instituicoes}
          defaultValue={instituicao}
          onChange={(e) => setInstituicao(e.target.value)}
        />
        <Input
          title={'unidade'}
          value={unidade}
          onChange={(e) => setUnidade(e.target.value)}
        />
        <Input
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
        />
        <div className="Button">
          <Buttons title={'Alterar'} onClick={Alterar} />
        </div>
      </div>
    </div>
  );
}
