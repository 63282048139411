import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import Selects from '../../../../components/Select';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import useCidade from '../../../../components/zustand/cidadeState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms() {
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [estados, setEstados] = useState([]);
  const [double, setDouble] = useState(false);

  const cidadeId = useCidade((state) => state.cidadeId);

  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (cidadeId) {
      api.get('/cidade/readOnly/',{params:{id:cidadeId}}).then(function (res) {
        setCidade(res.data.resposta[0].cidade);
        setEstado(res.data.resposta[0].idEstado);
      });
    }

    BuscarInstituicao();
  }, []);

  const BuscarInstituicao = () => {
    api
      .get('/estado/getall')
      .then(function (res) {
        let selectEstados = [];

        res.data.forEach((item) => {
          selectEstados.push({ value: item.id, label: item.estado });
        });

        setEstados(selectEstados);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Alterar = () => {
    api
      .post('/cidade/update/',{params:{id:cidadeId}}, {
        id: cidadeId,
        cidade: cidade,
        estado: estado,
      })
      .then(function (r) {
        toast.success(r.data.response  || 'Cidade alterada com sucesso!'
          );
        setTimeout(function () {
          Navigate('/cidade/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Erro ao alterar cidade!');
        setDouble(false);
      });
  };

  return (
    <>
      <Alerta />

      <div className="Component-Box">
        <div className="Component-Box-Buttons-Volta">
          <Buttons
            onClick={() => {
              Navigate('/cidade/list');
            }}
            title={<ArrowBackIcon />}
          />
        </div>
        <ScreenLogo title="Alterar Cidade" />

        <div className="Box-Body">
          <Selects
            props={'Estados'}
            data={estados}
            defaultValue={estado}
            onChange={(e) => setEstado(e.target.value)}
          />
          <Input
            title={'Cidade'}
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          />

          <div className="Button">
            <Buttons
              title={'Alterar'}
              disabled={double}
              onClick={() => {
                setDouble(true);
                Alterar();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
