import {
  Autocomplete,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import api from '../../../../services/api';
import useCidade from '../../../../components/zustand/cidadeState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Cidades() {
  const [busca, setbusca] = useState([]);
  const [estados, setEstados] = useState([]);
  const [estadoSelectId, setEstadoSelectId] = useState(null);
  const Navigate = useNavigate();
  const setCidadeId = useCidade((state) => state.setCidadeId);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const BuscarSelect = () => {
    api
      .get(`/estado/readonly2`)
      .then((response) => {
        var listEstados = response.data.resposta.map((item) => {
          return {
            id: item.id,
            label: item.estado,
          };
        });
        setEstados(listEstados);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const BuscarCidades = () => {
    api
      .get(
        `/cidade/paginado?page=${page || 1}${
          estadoSelectId ? `&state=${estadoSelectId}` : ''
        }`
      )
      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    BuscarCidades();
    BuscarSelect();
  }, []);

  useEffect(() => {
    BuscarCidades();
  }, [page, estadoSelectId]);

  const Excluir = (id) => {
    api
      .post('/cidade/delete/',{params:{id:id}})
      .then(function (res) {
        BuscarCidades();
        toast.success(res.data.response || 'Cidade excluída com sucesso!');
      })
      .catch(function (error) {
        toast.error(error.response.data.message || 'Erro ao excluir cidade!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />

      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();
                    }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cidades" />
      <div className="Component-Box-Buttons">
        <Buttons
          onClick={() => {
            Navigate('/cidade/create');
          }}
          title={'Adicionar nova cidade'}
        />
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Autocomplete
                  disablePortal
                  options={estados}
                  onChange={(_, value) => {
                    setEstadoSelectId(value ? value.id : null);
                  }}
                  sx={{ width: 200, maxWidth: '50%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="Estados" />
                  )}
                />
              </TableCell>
              <TableCell>
                <h3>Cidade</h3>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{row.estado}</TableCell>
                      <TableCell>{row.cidade}</TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            setCidadeId(row.id);
                            Navigate(`/cidade/update/`);
                          }}
                          title={'Alterar '}
                        />
                      </TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            Excluir(row.id);
                          }}
                          title={'Excluir'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
