import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(false);

  AuthProvider.propTypes = { children: PropTypes.element.isRequired };

  return (
    <AuthContext.Provider
      value={{ authenticated, setAuthenticated, token, setToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}
