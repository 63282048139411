import * as React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Input({ title, value, onChange, inputProps, type, error, helperText, name, disabled }) {
  Input.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    type: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  const [inputValue, setInputValue] = useState(value)
	useEffect(() => {
		setInputValue(value)
	}, [value])

  return (
    <>
    <TextField
    disabled={disabled}
      name={name}
      style={{ width: '100%' }}
      label={title}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value)
        if (onChange) onChange(e)
      }}
      inputProps={inputProps}
      type={type}
      error={error ? true : false}
      helperText={helperText}
        />
    </>
  );
}
