import React from 'react';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ApartmentIcon from '@mui/icons-material/Apartment';

const ItensMenu = [
  {
    name: 'Cadastros',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/cadastro',
    icon: <PersonAddAltIcon sx={{ cursor: 'pointer' }} />,
  },
  {
    name: 'Sipom',
    id: 'IdListUnitIconMenu',
    key: 'KeyListUnitIconMenu',
    destiny: '/sipom',
    icon: <ApartmentIcon sx={{ cursor: 'pointer' }} />,
  },
  
];

export default ItensMenu;
