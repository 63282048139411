import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/Buton';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms(data) {
  const [estado, setEstado] = useState('');
  const [sigla, setSigla] = useState('');
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (data.row) {
      setEstado(data.row.estado);
      setSigla(data.row.sigla);
    }
  }, [data]);

  const Cadastrar = () => {
    api
      .post('/estado/create/', {
        estado: estado,
        sigla: sigla,
      })
      .then(function () {
        toast.success('Estado cadastrado com sucesso!');
        setTimeout(function () {
          Navigate('/estado/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao cadastrar estado!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/estado/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cadastrar Estados" />
            <div className="Box-Body">
        <TextField
          style={{ width: '100%' }}
          label="Estado"
          title={'Estado'}
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
        />
        <TextField
          style={{ width: '100%' }}
          label="Sigla"
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
          inputProps={{ maxLength: 5 }}
        />
        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Cadastrar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
