import React from 'react';
import PropTypes from 'prop-types';
import coruja from '../../assets/coruja.gif';
import './style.css';

export default function ScreenLogo({ title }) {
  ScreenLogo.propTypes = { title: PropTypes.string.isRequired };

  return (
    <div className="logo">
      <img src={coruja} alt="Logo" />
      <h1>{title}</h1>
    </div>
  );
}
