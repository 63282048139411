import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
// import FeedIcon from '@mui/icons-material/Feed';
// import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Buttons from '../../../components/Buton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import DescriptionIcon from '@mui/icons-material/Description';

export default function Menu() {
  const Navigate = useNavigate();

  const ItensMenu = [
    {
      name: 'Numeração de Documentos',
      icon: <DocumentScannerIcon />,
      destiny: '/documentos/list',
    },
    // {
    //   name: 'Produtividade',
    //   icon: <FeedIcon />,
    //   destiny: '/listar/produtividade',
    // },
    // {
    //   name: 'Estatística ',
    //   icon: <QueryStatsIcon />,
    //   destiny: '/estatisca',
    // },
    {
      name: 'Arquivos',
      id: 'IdListUnitIconMenu',
      key: 'KeyListUnitIconMenu',
      destiny: '/listar-files',
      icon: <DescriptionIcon sx={{ cursor: 'pointer' }} />,
    },
  ];

  return (
    <div className="Box-Menu">
      <div className="button-voltar">
        <Buttons
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            history.back();          }}
        />
      </div>
      <div className="idContainerPageMenu">
        <div className="idContainerMenu">
          {ItensMenu
            ? ItensMenu.map((e, index) => {
              return (
                <>
                  <Tooltip title="Cadastrar e visualizar Números de documentos.">
                    <div
                      className="idContainerIconMenu10"
                      key={index}
                      onClick={() => Navigate(`${e.destiny}`)}
                    >
                      {e.icon}
                      <div className="idContainerNameIconMenu">{e.name}</div>
                    </div>
                  </Tooltip>
                </>
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
}
