import React, { useState } from 'react';
import Buttons from '../../../../components/Buton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScreenLogo from '../../../../components/LogoTelas';
import Input from '../../../../components/Input';
import { FilledInput } from '@mui/material';
import api from '../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

const UploadPdfComponent = () => {
  const [file, setFile] = useState(null);
  const [nome, setNome] = useState('');
  const [armario, setArmario] = useState('');
  const [prateleira, setPrateleira] = useState('');
  const [caixa, setCaixa] = useState('');
  const [pasta, setPasta] = useState('');
  const Navigate = useNavigate();
  const [double, setDouble] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const salvarArquivo = (conteudo) => {
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('nome', nome);
    formData.append('extensao', '.pdf');
    formData.append('armario', armario);
    formData.append('prateleira', prateleira);
    formData.append('caixa', caixa);
    formData.append('pasta', pasta);
    formData.append('conteudo', conteudo);

    api
      .post('/pdf-dados', formData)
      .then((res) => {
        toast.success(res.data.message || 'Documento cadastrado com sucesso!');
        setTimeout(function () {
          Navigate('/listar-files');
        }, 1500);
      })
      .catch((error) => {
        toast.error(
          error.response.data.error || 'Erro ao cadastrar documento!'
        );
        setDouble(false);
      });
  };

  const handleUpload = async () => {
    try {
      if (!file) {
        console.error('Nenhum arquivo selecionado.');
        return;
      }

      const formData = new FormData();
      formData.append('pdf', file);

      const response = await api.post('/pdf-upload', formData);

      if (response.status === 200) {
        await salvarArquivo(response.data.textoExtraido);
      } else {
        toast.error(response.statusText || 'Erro ao fazer upload do PDF!');
      }
    } catch (error) {
      toast.error(error.response.data.error || 'Erro ao fazer upload do PDF!');
    }
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            window.history.back();
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Salvar documentos" />
      <div className="Box-Body">
        <Input
          title={'Nome do arquivo'}
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />

        <Input
          title={'Armario (exemplo: 1)'}
          value={armario}
          onChange={(e) => setArmario(e.target.value)}
        />

        <Input
          title={'Parteleira (exemplo: 1)'}
          value={prateleira}
          onChange={(e) => setPrateleira(e.target.value)}
        />

        <Input
          title={'Pasta (exemplo: 1)'}
          value={pasta}
          onChange={(e) => setPasta(e.target.value)}
        />

        <Input
          title={'Caixa (exemplo: 1)'}
          value={caixa}
          onChange={(e) => setCaixa(e.target.value)}
        />

        <FilledInput type="file" onChange={handleFileChange} />

        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={async () => {
              setDouble(true);
              await handleUpload();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadPdfComponent;
