import React, { useEffect, useState } from 'react';
import Buttons from '../../../../components/Buton';
import ScreenLogo from '../../../../components/LogoTelas';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import Alert2 from '../../../../components/Alert';
import alertState from '../../../../components/zustand/alertState';
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import './style.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import usePerfil from '../../../../components/zustand/perfilState';
import Input from '../../../../components/Input';
export default function Permissoes() {
  const [perfil, setPerfil] = useState('');
  const [unidade, setUnidade] = useState('');
  const [rota, setRota] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [agrupadores, setAgrupadores] = useState({});
  const [double, setDouble] = useState(false);
  const alertstateData = alertState((state) => state.alertData);
  const setAlertData = alertState((state) => state.setAlertData);
  const Navigate = useNavigate();
const [idPerfil, setIdPerfil] = useState('');
  Permissoes.PropTypes = { data: PropTypes.string.isRequired };

  const perfilId = usePerfil((state) => state.perfilId);

  useEffect(() => {
    if (perfilId) {
      api.get('/perfil/readonly/',{params:{id:perfilId}}).then(function (res) {
        setUnidade(res.data.dados.unidade);
        setPerfil(res.data.dados.perfil);
        setIdPerfil(res.data.dados.id)
      });
    }
    if(unidade){
      api.get('/unidade/getonly/',{params:{id:unidade}}).then(function (res) {
        setUnidades(res.data.unidade);
      });
    }
    BuscarRotas();
  }, [unidade]);

  const BuscarRotas = () => {
    api
      .get('/rota/getall')
      .then(function (res) {
        const agrupadoresMount = {};
        res.data.map((rota) => {
          const newAgrupador = agrupadoresMount[rota.agrupador] || [];
          if (rota.agrupador !== null) {
            newAgrupador.push({ ...rota, checked: false });
            agrupadoresMount[rota.agrupador] = newAgrupador;
          }
        });

        setAgrupadores(agrupadoresMount);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const PerfilRota = () => {
    api
      .post('/gestaorotas/create', {
        perfil: idPerfil,
        rota: rota === '' ? [] : rota,
        status: true,
      })
      .then(function () {
        setAlertData({
          severity: 'success',
          message: 'Perfil cadastrado com sucesso!',
        });
        setTimeout(function () {
          Navigate('/Perfil/list');
        }, 1500);
      })
      .catch((error) => {
        setAlertData({ severity: 'error', message: error.response.data.error });
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/perfil/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Permissão Perfil" />
      {alertstateData ? (
        <Alert2
          sev={alertstateData.severity}
          child={alertstateData.message}
        ></Alert2>
      ) : (
        <></>
      )}
      <div className="Box-Body">
        <Input
          title={'unidade'}
          value={unidades}
          disabled={true}
          onChange={(e) => setUnidades(e.target.value)}
        />
        <TextField
          title={'Perfil'}
          value={perfil}
          disabled={true}
          onChange={(e) => setPerfil(e.target.value)}
          label="Perfil"
          style={{ width: '100%' }}
        />

        <div className="box-permissao">
          {agrupadores &&
            Object.keys(agrupadores).length > 0 &&
            Object.keys(agrupadores).map((agrupador, index) => {
              return (
                <div className="permissao-grid" key={index}>
                  <div className="permissao-item" key={index}>
                    <div className="permissao-item-title">
                      <FormControlLabel
                        label={agrupador}
                        control={
                          <Checkbox
                            inputProps={{ 'aria-label': 'controlled' }}
                            indeterminate={agrupadores[agrupador].some(
                              (a) => a.checked === true
                            )}
                            onChange={(e) => {
                              const newAgrupador = agrupadores;

                              newAgrupador[agrupador].forEach((a) => {
                                a.checked = e.target.checked;
                              });

                              setAgrupadores(newAgrupador);
                              setRota(newAgrupador[agrupador].map((a) => a.id));
                            }}
                          />
                        }
                      />
                      {agrupadores[agrupador].map((rota, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              ml: 3,
                            }}
                          >
                            <FormControlLabel
                              label={rota.obs}
                              control={
                                <Checkbox
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  checked={
                                    agrupadores[agrupador][index].checked
                                  }
                                  agrupador={agrupador}
                                  onChange={(e) => {
                                    const newAgrupador = agrupadores;

                                    newAgrupador[agrupador][index].checked =
                                      e.target.checked;

                                    setAgrupadores({ ...newAgrupador });
                                    setRota(agrupadores[agrupador][index].id);
                                  }}
                                />
                              }
                            />
                          </Box>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              PerfilRota();
            }}
          />
        </div>
      </div>
    </div>
  );
}
