import React from 'react';
import Modal from '@mui/material/Modal';
import Buttons from '../../../../components/Buton';
import './style.css';
import Chart from 'react-google-charts';
import api from '../../../../services/api';
import { useEffect } from 'react';

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [data, setData] = React.useState([]);
  const [data2, setData2] = React.useState([]);
  const [teste, setTeste] = React.useState(false);

  const paleta = {
    0: 'grey',
    1: 'blue',
    2: 'yellow',
    3: 'red',
    4: 'orange',
    5: 'green',
    6: 'cyan',
    7: '#DAA520',
    8: '#76A7FA',
    9: '#703593',
    10: 'green',
  };

  const documentosLançados = () => {
    api.get('/documento/buscarPorDoc').then((res) => {
      const data = res.data || [];

      const array = [['S', 'Q']];
      data.map((item) => {
        array.push([item.sigla, Number(item.quantidade)]);
      });

      setData(array);
    });
  };

  const documentosLancadosPorUsuarios = () => {
    api.get('/documento/buscarPorUsuario').then((res) => {
      const data = res.data || [];
      const array = [['Nome', 'Total', { role: 'style' }]];
      data.map((value, index) => {
        let i =
          index < 11
            ? index
            : index > 10 && index < 100
            ? Math.floor(index / 10)
            : Math.floor(index / 100);

        array.push([value.nome, Number(value.quantidade), paleta[i]]);
      });

      setData2(array);
    });
  };

  useEffect(() => {
    documentosLançados();
    documentosLancadosPorUsuarios();
  }, []);

  useEffect(() => {
    if (data2.length > 0) {
      setTeste(true);
    }
  }, [data2]);

  return (
    <div>
      <Buttons
        onClick={handleOpen}
        title={'Estatística'}
        style={{ height: '100%' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="box-Modal">
          <Buttons onClick={handleClose} title={'Fechar'} />
          <div className="modal">
            {teste ? (
              <Chart
                width={'100%'}
                height={'100%'}
                chartType="ColumnChart"
                data={data2}
                options={{
                  legend: { position: 'none', maxLines: 3 },
                  bar: { groupWidth: '30%' },
                  isStacked: true,
                  title: 'Quantidade de documentos lançados por usuário',
                }}
              />
            ) : (
              <p>Não há dados para apresentar.</p>
            )}

            {data.length > 0 ? (
              <Chart
                chartType="PieChart"
                data={data}
                options={{
                  title: 'Quantidade de documentos lançados por unidade',
                }}
                width={'100%'}
                height={'100%'}
              />
            ) : (
              <p>Não há dados para apresentar.</p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
