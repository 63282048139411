import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import Buttons from '../../../../components/Buton/index';
import '../style.scss';
import { useNavigate } from 'react-router-dom';
import ScreenLogo from '../../../../components/LogoTelas/';
import useUnidade from '../../../../components/zustand/unidadeState';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Input, Pagination, PaginationItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function AcccessibleTable() {
  const [busca, setbusca] = useState([]);
  const Navigate = useNavigate();
  const setUnidadeId = useUnidade((state) => state.setUnidadeId);
  const [search, setSearch] = React.useState('');
  const [searchUnidade, setSearchUnidade] = React.useState('');
  const [searchSigla, setSearchSigla] = React.useState('');

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const filter = () => {
    if (search || searchUnidade || searchSigla) {
      const newData = busca.filter((item) => {
        const itemData = item.instituicao.toUpperCase();
        const itemData2 = item.unidade.toUpperCase();
        const itemData3 = item.sigla.toUpperCase();
        const textData =
          search.toUpperCase() ||
          searchUnidade.toUpperCase() ||
          searchSigla.toUpperCase();
        return (
          itemData.indexOf(textData) > -1 ||
          itemData2.indexOf(textData) > -1 ||
          itemData3.indexOf(textData) > -1
        );
      });
      setbusca(newData);
    }
  };

  const BuscarUnidade = () => {
    api
      .get(`/unidade/paginado?page=${page || 1}`)

      .then(function (res) {
        setbusca(res.data.data);
        setTotalPages(res.data.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    BuscarUnidade();
    filter();
  }, [page]);

  const Excluir = (id) => {
    api
      .post('/unidade/delete/',{params:{id:id}})
      .then(function (res) {
        BuscarUnidade();
        toast.success(res.data.message || 'Unidade excluída com sucesso!');
      })
      .catch(function (error) {
        toast.error(error.response.data.error || 'Erro ao excluir unidade!');
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            history.back();          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Unidades" />
      
      <div className="Component-Box-Buttons">
        <Buttons
          onClick={() => {
            Navigate('/unidade/create');
          }}
          title={'Adicionar nova Unidade'}
        />
      </div>

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: '50%' }}
          aria-label="caption table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <h3>
                  Instituição
                  <Button onClick={null}>
                    <SearchIcon />
                  </Button>
                  <Input
                    type="search"
                    placeholder="Instituição"
                    value={search}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        BuscarUnidade();
                      }
                      setSearch(e.target.value);
                      setbusca(
                        busca.filter((item) =>
                          item.instituicao
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                      );
                    }}
                  />
                </h3>
              </TableCell>
              <TableCell>
                <Input
                  type="search"
                  placeholder="Unidade"
                  value={searchUnidade}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      BuscarUnidade();
                    }
                    setSearchUnidade(e.target.value);
                    setbusca(
                      busca.filter((item) =>
                        item.unidade
                          .toLowerCase()
                          .includes(searchUnidade.toLowerCase())
                      )
                    );
                  }}
                />
              </TableCell>
              <TableCell>
                <h3>
                  <Input
                    type="search"
                    placeholder="Sigla"
                    value={searchSigla}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        BuscarUnidade();
                      }
                      setSearchSigla(e.target.value);
                      setbusca(
                        busca.filter((item) =>
                          item.sigla
                            .toLowerCase()
                            .includes(searchSigla.toLowerCase())
                        )
                      );
                    }}
                  />
                </h3>
              </TableCell>
              <TableCell />
              <TableCell />

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(busca) && busca.length > 0
              ? busca.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.instituicao}
                      </TableCell>
                      <TableCell>{row.unidade}</TableCell>
                      <TableCell>{row.sigla}</TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => {
                            setUnidadeId(row.id);
                            Navigate(`/unidade/update/`);
                          }}
                          title={'Alterar'}
                        />
                      </TableCell>
                      <TableCell>
                        <Buttons
                          onClick={() => Excluir(row.id)}
                          title={'Excluir'}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="Pagination">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChange}
          renderItem={(item) => <PaginationItem {...item} />}
        />
      </div>
    </div>
  );
}
