import {create} from 'zustand';

const useGrupo = create((set) => ({
  grupoId: '',
  setGrupoId: (id) => set(() => ({
    grupoId
      : id
  })),
}));

export default useGrupo;