import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import api from '../../../../services/api';
import ScreenLogo from '../../../../components/LogoTelas';
import useGrupo from '../../../../components/zustand/grupoState';
import Selects from '../../../../components/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms() {
  const grupoId = useGrupo((state) => state.grupoId);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();

  const [grupo, setGrupo] = useState('');
  const [unidade, setUnidade] = useState('');
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    if (grupoId) {
      api.get('/grupo/getonly/',{params:{id:grupoId}}).then(function (res) {
        setUnidade(res.data.resposta.unidade);
        setGrupo(res.data.resposta.grupo);
      });
    }
    BuscaUnidade();
  }, []);

  const BuscaUnidade = () => {
    api
      .get('/unidade/getall')
      .then(function (res) {
        let selecUnidades = [];
        res.data.forEach((item) => {
          selecUnidades.push({
            value: item.id,
            label: item.unidade,
          });
        });

        setUnidades(selecUnidades);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Alterar = () => {
    api
      .post('/grupo/update/',{params:{id:grupoId}}, {
        id: grupoId,
        unidade: parseInt(unidade),
        grupo: grupo,
      })
      .then(function () {
        toast.success('Grupo alterado com sucesso!');
        setTimeout(function () {
          Navigate('/grupo/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao alterar grupo!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/grupo/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Alterar Grupo" />
      

      <div className="Box-Body">
        <Selects
          props={'Unidades'}
          data={unidades}
          defaultValue={unidade}
          onChange={(e) => setUnidade(e.target.value)}
        />
        <Input
          title={'Grupo'}
          value={grupo}
          onChange={(e) => setGrupo(e.target.value)}
        />
        <div className="Button">
          <Buttons
            title={'Alterar'}
            disabled={double}
            onClick={() => {
              setDouble(true);
              Alterar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
