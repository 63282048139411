import React, { useEffect, useState } from 'react';
import Input from '../../../../components/Input';
import Buttons from '../../../../components/Buton';
import Selects from '../../../../components/Select';
import api from '../../../../services/api';
import PropTypes from 'prop-types';
import ScreenLogo from '../../../../components/LogoTelas';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';
import Alerta from '../../../../components/Alerta';

export default function Forms(data) {
  const [instituicao, setInstituicao] = useState('');
  const [unidade, setUnidade] = useState('');
  const [sigla, setSigla] = useState('');
  const [instituicoes, setInstituicoes] = useState([]);
  const [double, setDouble] = useState(false);
  const Navigate = useNavigate();

  Forms.PropTypes = { data: PropTypes.string.isRequired };

  useEffect(() => {
    if (data.row) {
      setInstituicao(data.row.instituicao);
      setUnidade(data.row.unidade);
      setSigla(data.row.sigla);
    }

    BuscarInstituicao();
  }, [data]);

  const BuscarInstituicao = () => {
    api
      .get('/instituicao/getall')
      .then(function (res) {
        let selectInstituicoes = [];

        res.data.resposta.forEach((item) => {
          selectInstituicoes.push({ value: item.id, label: item.instituicao });
        });

        setInstituicoes(selectInstituicoes);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const Cadastrar = () => {
    api
      .post('/unidade/create', {
        instituicao: parseInt(instituicao),
        unidade: unidade,
        sigla: sigla,
      })
      .then(function (res) {
        toast.success(res.data.message || 'Unidade cadastrada com sucesso!')
        setTimeout(function () {
          Navigate('/unidade/list');
        }, 1500);
      })
      .catch((error) => {
        toast.error(error.response.data.error || 'Erro ao cadastrar unidade!');
        setDouble(false);
      });
  };

  return (
    <div className="Component-Box">
      <Alerta />
      <div className="Component-Box-Buttons-Volta">
        <Buttons
          onClick={() => {
            Navigate('/unidade/list');
          }}
          title={<ArrowBackIcon />}
        />
      </div>
      <ScreenLogo title="Cadastrar Unidade" />

      <div className="Box-Body">
        <Selects
          props={'Instituição'}
          data={instituicoes}
          onChange={(e) => setInstituicao(e.target.value)}
        />
        <Input
          title={'unidade'}
          value={unidade}
          onChange={(e) => setUnidade(e.target.value)}
        />
        <Input
          title={'sigla'}
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
        />
        <div className="Button">
          <Buttons
            title={'Cadastrar'}
            disabled={double}
            onClick={() => {
              setDouble(true), Cadastrar();
            }}
          />
        </div>
      </div>
    </div>
  );
}
